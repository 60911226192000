<template>
  <div class="auth-custom-header">
    <div class="logo-wrapper">
      <div class="smari-logo">
        <img src="/images/smari_logo.png" />
      </div>
    </div>
  </div>
  <div class="header-title"><b>スマリ宅配ボックス発送</b></div>
  <div class="outline">
    <div class="describe">
      <b
        >スマホでお住まいの宅配ボックスから<br />
        カンタンに商品の発送が行えます。</b
      >
    </div>
    <div class="icon-wrapper">
      <img
        class="mobile-icon"
        src="/images/mobile-icon.png"
        alt="スマートフォン"
      />
      <img
        class="deliverybox-icon"
        src="/images/deliverybox-icon.png"
        alt="宅配ボックス"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthCustomHeader",
}
</script>

<style scoped>
.auth-custom-header {
  padding: 10px;
}
.logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 0;
}
.smari-logo {
  width: 180px;
  height: auto;
}
.header-title {
  margin: 0 5% 0;
  border-bottom: 1px solid #092c0b;
  text-align: center;
  margin-bottom: 10px;
}
.outline {
  margin-bottom: 20px;
}
.describe {
  text-align: center;
}
.icon-wrapper {
  width: 150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-icon {
  height: 40px;
}
.deliverybox-icon {
  height: 55px;
}
</style>

<template>
  <header>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <div class="navbar-item">
          <img src="/images/smari_logo.png" />
        </div>
        <a
          role="button"
          class="navbar-burger burger burger-custom"
          aria-label="menu"
          aria-expanded="false"
          data-target="burger-menu"
          @click="is_open = !is_open"
          :class="{ 'is-active': is_open }"
        >
          <span aria-hidden="true" class="custom-line"></span>
          <span aria-hidden="true" class="custom-line"></span>
          <span aria-hidden="true" class="custom-line"></span>
        </a>
      </div>
      <div
        id="burger-menu"
        class="navbar-menu"
        :class="{ 'is-active': is_open }"
      >
        <div class="navbar-start navbar-custom">
          <div class="navbar-item">
            <router-link :to="{ path: '/reception/history' }"
              >宅配ボックス発払受付履歴</router-link
            >
          </div>
          <div class="navbar-item">
            <router-link :to="{ path: '/user/edit' }"
              >アカウント情報変更</router-link
            >
          </div>
          <div class="navbar-item">
            <slot name="signout-button"></slot>
          </div>
        </div>
      </div>
    </nav>
    <div class="header-title"><b>スマリ宅配ボックス発送</b></div>
  </header>
</template>

<script>
export default {
  name: "CostomHeader",
  props: {},
  data() {
    return {
      is_open: false,
    }
  },
}
</script>

<style scoped>
header {
  padding: 10px;
}
.logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}
.smari-logo {
  width: 200px;
  height: auto;
}
.smari-logo img {
  width: 100%;
}
.header-title {
  margin: 0 5% 0;
  border-bottom: 1px solid #092c0b;
  text-align: center;
}
.burger-custom {
  background-color: #092c0b;
}
.burger-custom:hover {
  background-color: #092c0b;
}
.navbar-burger .custom-line {
  background-color: #fff;
}
.navbar-custom {
  text-align: right;
  padding-right: 10px;
}
</style>

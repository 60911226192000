import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/",
    name: "RouteHub",
    component: () => import("../views/RouteHub.vue"),
  },
  {
    path: "/reception",
    name: "Reception",
    component: () => import("../views/Reception.vue"),
  },
  {
    path: "/payment-reception",
    name: "PaymentReception",
    component: () => import("../views/PaymentReception.vue"),
  },
  {
    path: "/payment/store/select",
    name: "PaymentStoreSelect",
    component: () => import("../views/PaymentStoreSelect.vue"),
  },
  {
    path: "/payment-reception-failed",
    name: "PaymentReceptionFailed",
    component: () => import("../views/PaymentReceptionFailed.vue"),
  },
  {
    path: "/payment-reception-complete",
    name: "PaymentReceptionComplete",
    component: () => import("../views/PaymentReceptionComplete.vue"),
  },
  {
    path: "/payment/store/key-set",
    name: "PaymentStoreKeySet",
    component: () => import("../views/PaymentStoreKeySet.vue"),
  },
  {
    path: "/payment/store/key-set-confirm",
    name: "PaymentStoreKeySetConfirm",
    component: () => import("../views/PaymentStoreKeySetConfirm.vue"),
  },
  {
    path: "/payment/store/complete",
    name: "PaymentStoreComplete",
    component: () => import("../views/PaymentStoreComplete.vue"),
  },
  {
    path: "/store/select",
    name: "StoreSelect",
    component: () => import("../views/StoreSelect.vue"),
  },
  {
    path: "/store/area/error",
    name: "StoreAreaError",
    component: () => import("../views/StoreAreaError.vue"),
  },
  {
    path: "/store/key-set",
    name: "StoreKeySet",
    component: () => import("../views/StoreKeySet.vue"),
  },
  {
    path: "/store/key-set-confirm",
    name: "StoreKeySetConfirm",
    component: () => import("../views/StoreKeySetConfirm.vue"),
  },
  {
    path: "/store/complete",
    name: "StoreComplete",
    component: () => import("../views/StoreComplete.vue"),
  },
  {
    path: "/user/regist",
    name: "UserRegist",
    component: () => import("../views/UserRegist.vue"),
  },
  {
    path: "/user/edit",
    name: "UserEdit",
    component: () => import("../views/UserEdit.vue"),
  },
  {
    path: "/user/edit-complete",
    name: "UserEditComplete",
    component: () => import("../views/UserEditComplete.vue"),
  },
  {
    path: "/reception/history",
    name: "ReceptionHistory",
    component: () => import("../views/ReceptionHistory.vue"),
  },
  {
    path: "/reception/gmo-payment/:order_no/cancel",
    name: "ReceptionCancel",
    component: () => import("../views/ReceptionCancel.vue"),
  },
  {
    path: "/locker/pre-regist",
    name: "LockerPreRegist",
    component: () => import("../views/LockerPreRegist.vue"),
  },
  {
    path: "/locker/regist",
    name: "LockerRegist",
    component: () => import("../views/LockerRegist.vue"),
  },
  {
    path: "/locker/regist-confirm",
    name: "LockerRegistConfirm",
    component: () => import("../views/LockerRegistConfirm.vue"),
  },
  {
    path: "/locker/regist-complete",
    name: "LockerRegistComplete",
    component: () => import("../views/LockerRegistComplete.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

import { createStore } from "vuex"

export default createStore({
  state: {
    deliverybox: {
      code: "",
      store_no: "",
      store_address: "",
      store_name: "",
      store_locker_name: "",
      store_locker_key: "",
    },
    area_error: {
      prefecture: "",
      city: "",
      address: "",
      address_no: "",
      address_ext: "",
    },
    box_reception: {
      box_no: "",
    },
    transaction_token: "",
    locker: {
      code: "",
      name: "",
    },
    locker_location: {
      zip: "",
      prefecture: "",
      city: "",
      address: "",
      address_no: "",
      address_ext: "",
    },
    user: {
      cognito_uuid: "",
    },
    cancel_order: {
      created_at: "",
      service_name: "",
      order_no: "",
    },
    service: {
      code: "",
      name: "",
      payment_price: "",
      is_payment_enabled: "",
    }
  },
  mutations: {
    setDeliverybox(state, values) {
      state.deliverybox.code = values.code
      state.deliverybox.store_no = values.store_no
      state.deliverybox.store_address = values.store_address
      state.deliverybox.store_name = values.store_name
      state.deliverybox.store_locker_name = values.store_locker_name
      state.deliverybox.store_locker_key = values.store_locker_key
    },
    setAreaError(state, values) {
      state.area_error.store_address = values.store_address
      state.area_error.store_name = values.store_name
      state.area_error.store_locker_name = values.store_locker_name
    },
    setService(state, values) {
      state.service.code = values.code
      state.service.name = values.name
      state.service.payment_price = values.payment_price
      state.service.is_payment_enabled = values.is_payment_enabled
    },
    clearDeliverybox(state) {
      state.deliverybox.code = ""
      state.deliverybox.store_no = ""
      state.deliverybox.store_address = ""
      state.deliverybox.store_name = ""
      state.deliverybox.store_locker_name = ""
      state.deliverybox.store_locker_key = ""
    },
    setLocker(state, values) {
      state.locker.code = values.code
      state.locker.name = values.name
    },
    setLockerLocation(state, values) {
      state.locker_location.zip = values.zip
      state.locker_location.prefecture = values.prefecture
      state.locker_location.city = values.city
      state.locker_location.address = values.address
      state.locker_location.address_no = values.address_no
      state.locker_location.address_ext = values.address_ext
    },
    clearLockerLocation(state) {
      state.locker_location.zip = ""
      state.locker_location.prefecture = ""
      state.locker_location.city = ""
      state.locker_location.address = ""
      state.locker_location.address_no = ""
      state.locker_location.address_ext = ""
    },
    clearCancelOrder(state) {
      state.cancel_order.created_at = ""
      state.cancel_order.order_no = ""
      state.cancel_order.service_name =""
    },
    setUser(state, values) {
      state.user.cognito_uuid = values.cognito_uuid
    },
    setBoxReception(state, values) {
      state.box_reception.box_no = values.box_no
    },
    setTransactionToken(state, transaction_token) {
      state.transaction_token = transaction_token
    },
    setCancelOrder(state, values) {
      state.cancel_order.created_at = values.created_at
      state.cancel_order.service_name = values.service_name
      state.cancel_order.order_no = values.order_no
    }
  },
  actions: {},
  modules: {},
})

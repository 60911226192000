<template>
  <div class="amplify-flex amplify-field amplify-checkboxfield">
    <div class="scroll-area">
      <PrivacyPolicy />
    </div>
    <label @click="checked = !checked">
      同意する
      <input
        aria-invalid="false"
        type="checkbox"
        name="acknowledgement"
        value="yes"
        v-bind:disabled="isCheckDisabled"
      />
    </label>
    <p v-if="errorMessage" class="amplify-text amplify-field__error-message">
      {{ errorMessage[0] }}
    </p>
  </div>
</template>

<script>
import PrivacyPolicy from "./PrivacyPolicy.vue"

export default {
  name: "PersonalInformationCheckBox",
  components: {
    PrivacyPolicy,
  },
  props: {
    errorMessage: Array,
  },
  data() {
    return {
      checked: false,
      isCheckDisabled: true,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.checkScrollArea, {
      capture: true,
    })
    const element = document.querySelector(".scroll-area")
    const height = element.scrollHeight
    const client_height = element.clientHeight
    // スクロールするテキストが少なくてスクロールできない場合に対応
    if (height === client_height) {
      this.isCheckDisabled = true
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScrollArea, {
      capture: true,
    })
  },
  methods: {
    checkScrollArea() {
      const element = document.querySelector(".scroll-area")
      const top = element.scrollTop
      const height = element.scrollHeight
      const client_height = element.clientHeight
      // 一部モニタサイズだと下記計算だとチェックできない現象が発生するためマージンを設定
      const margin = 100
      const is_scrolled = height - top <= client_height + margin
      console.log(is_scrolled)
      if (is_scrolled) {
        this.isCheckDisabled = false
      }
    },
  },
}
</script>

<style scoped>
.scroll-area {
  overflow-y: scroll;
  border: 1px solid hsl(210deg 6% 70%);
  height: 200px;
  text-align: left;
  font-size: 0.8rem;
  padding: 0.5rem;
}
</style>

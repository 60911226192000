export const jaDict = {
  "Account recovery requires verified contact information":
    "アカウントの復旧には確認済みの連絡先が必要です",
  "An account with the given email already exists.":
    "入力されたメールアドレスのアカウントが既に存在します",
  "Back to Sign In": "サインインに戻る",
  "Change Password": "パスワードを変える ",
  "Signing in": "サインイン中",
  Code: "コード",
  Confirm: "確定",
  "Confirm a Code": "コードを確認",
  "Confirm Password": "パスワードの確認",
  "Confirm Sign In": "サインインする",
  "Confirm Sign Up": "登録する",
  "Confirmation Code": "確認コード",
  "Create a new account": "新しいアカウントを作る",
  "Create account": "アカウントを作る ",
  "Create Account": "アカウントを作る",
  Email: "メールアドレス",
  "Enter your password": "パスワードを入力 ",
  "Enter your username": "ユーザー名を入力 ",
  "Forgot Password": "パスワードを忘れた ",
  "Forgot your password? ": "パスワードを忘れた場合はこちら",
  "Have an account? ": "アカウントを持っていますか？",
  "Incorrect username or password": "ユーザー名かパスワードが異なります ",
  "Invalid password format": "パスワードの形式が無効です ",
  "Invalid phone number format":
    "不正な電話番号の形式です。\n+12345678900 の形式で入力してください",
  "Lost your code? ": "コードを失くしましたか？",
  "New Password": "新しいパスワード",
  "New password": "新しいパスワード",
  "No account? ": "アカウントが無いとき ",
  or: "又は",
  Password: "パスワード",
  "Password attempts exceeded": "サインインの試行回数が上限に達しました",
  "Phone Number": "電話番号",
  "Resend Code": "コードを再送信",
  "Reset password": "パスワードをリセット ",
  "Reset your password": "パスワードをリセットする",
  "Send Code": "コードを送信",
  "Sign in": "サインイン",
  "Sign In": "サインイン ",
  "Sign in to your account": "アカウントにサインイン ",
  "Sign In with Amazon": "Amazonでサインイン",
  "Sign In with Facebook": "Facebookでサインイン",
  "Sign In with Google": "Googleでサインイン",
  "Sign Out": "サインアウト ",
  "Sign Up": "登録 ",
  Skip: "スキップ",
  Submit: "送信",
  "User already exists": "既にユーザーが存在しています ",
  "User does not exist": "ユーザーが存在しません ",
  Username: "ユーザー名 ",
  "Username cannot be empty": "ユーザー名は入力必須です",
  Verify: "確認",
  "Verify Contact": "連絡先を確認",
  "Family Name": "姓",
  "Given Name": "名",
  "Your passwords must match": "パスワードが一致していません",
  "We Emailed You": "確認コードをメールアドレスに送信しました",
  "Enter your code": "確認コードを入力してください",
  "Enter your email": "Eメールアドレスを入力してください",
  "Reset your Password": "パスワードのリセット",
  "We Sent A Code": "アカウントの認証がまだ完了していません",
  "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
    "ログインするにはメールで送信した確認コードを入力してください。",
  "Incorrect username or password.":
    "メールアドレスかパスワードが間違っています。",
  "Password did not conform with policy: Password not long enough":
    "パスワードは8文字以上である必要があります。",
  "Password did not conform with policy: Password must have uppercase characters":
    "パスワードは大文字の英字を含んでいる必要があります。",
  "Password did not conform with policy: Password must have numeric characters":
    "パスワードは数字を含んでいる必要があります。",
  "Password did not conform with policy: Password must have lowercase characters":
    "パスワードは小文字の英字を含んでいる必要があります",
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
    "パスワードは8文字以上である必要があります。",
  "Invalid phone number format.": "電話番号のフォーマットが誤っています。",
  "Username/client id combination not found.":
    "このEメールアドレスは登録されていません。",
  "Invalid verification code provided, please try again.":
    "確認コードが誤っています。もう一度ご確認ください。",
  "User does not exist.": "メールアドレスかパスワードが間違っています。",
  "Your code is on the way. To log in, enter the code we emailed to":
    "ログインするにはメールで送信した確認コードを入力してください。送信アドレス：",
  "It may take a minute to arrive.": " ",
  "Password must have at least 8 characters": "パスワードは8文字以上である必要があります",
  "Password must have lower case letters": "パスワードは小文字を含む必要があります",
  "Password must have upper case letters": "パスワードは大文字を含む必要があります",
  "Password must have numbers": "パスワードは数字を含む必要があります",
}

<script setup>
import { Auth } from "aws-amplify"
import {
  Authenticator,
  AuthenticatorSignUpFormFields,
  useAuthenticator,
} from "@aws-amplify/ui-vue"
import "@aws-amplify/ui-vue/dist/style.css"
import PersonalInformationCheckBox from "./PersonalInformationCheckBox.vue"
import { toRefs } from "vue"
import CustomHeader from "./CustomHeader.vue"
import AuthCustomHeader from "./AuthCustomHeader.vue"
import { parsePhoneNumber, isValidNumber } from "libphonenumber-js"
import validate from "validate.js"
import moment from "moment"
import settings from "../settings"

const { validationErrors } = toRefs(useAuthenticator())
const genders = settings.genders
function formatBirthdate(form_data) {
  const isHalfSizeNumber = (value) => {
    if (!value) {
      return false
    }
    const half_size_pattern = /^[0-9]+$/
    return value.match(half_size_pattern)
  }
  // 西暦の桁数が少ない際の挙動がおかしいため対応
  if (!form_data.birthdate_year) {
    return ""
  }
  if (form_data.birthdate_year.length < 4) {
    form_data.birthdate_year = "1000"
  }
  const year = form_data.birthdate_year
  const month = form_data.birthdate_month
    ? form_data.birthdate_month.padStart(2, "0")
    : "00"
  const day = form_data.birthdate_day
    ? form_data.birthdate_day.padStart(2, "0")
    : "00"

  if (
    !isHalfSizeNumber(year) ||
    !isHalfSizeNumber(month) ||
    !isHalfSizeNumber(day)
  ) {
    return ""
  }
  return `${year}-${month}-${day}`
}
//submitすると国番号が付与される仕様のため無理やり対応
function formatPhonenumber(phone_number) {
  const japan_code = "+81"
  const default_code = "+1"
  if (!phone_number) {
    return ""
  }
  //先頭にデフォルトの国番号が付与されている場合は取り除く
  if (phone_number.substring(0, 2) === default_code) {
    return `${japan_code}${phone_number.substring(2)}`
  }
  return `${japan_code}${phone_number}`
}

const services = {
  async validateCustomSignUp(form_data) {
    const error_messages = {
      presence: "^この項目は必須です",
      datetime: "^半角数字で有効な日付を入力してください",
      numericality: "^半角数字のみで入力してください",
      valid_phone_number: "^有効な電話番号を入力してください",
      inclusion: "^有効な値が選択されていません",
    }
    // 国番号付き電話番号のバリデーション
    validate.validators.validPhoneNumber = (value) => {
      const phone_number = formatPhonenumber(value)
      if (isValidNumber(phone_number)) {
        return
      }
      return error_messages.valid_phone_number
    }

    // 日付の設定
    validate.extend(validate.validators.datetime, {
      parse: (value, options) => {
        return +moment.utc(value)
      },
      format: (value, options) => {
        let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss"
        return moment.utc(value).format(format)
      },
    })

    const constraints = {
      family_name: {
        presence: {
          allowEmpty: false,
          message: error_messages.presence,
        },
      },
      given_name: {
        presence: {
          allowEmpty: false,
          message: error_messages.presence,
        },
      },
      phone_number: {
        presence: {
          allowEmpty: false,
          message: "^半角数字のみで入力してください",
        },
        numericality: {
          message: error_messages.numericality,
        },
        validPhoneNumber: true,
      },
      locale: {
        presence: {
          allowEmpty: false,
          message: "^半角数字のみで入力してください",
        },
        numericality: {
          message: error_messages.numericality,
        },
        length: {
          is: 7,
          message: "^7桁で入力してください",
        },
      },
      gender: {
        presence: {
          allowEmpty: false,
          message: error_messages.presence,
        },
        inclusion: {
          within: Object.keys(genders).map((key) => {
            return genders[key]
          }),
          message: error_messages.inclusion,
        },
      },
      acknowledgement: {
        presence: {
          allowEmpty: false,
          message:
            "^「スマリ宅配ボックスサービスのご利用にあたって」及び「スマリ宅配ボックスサービスに係る個人情報保護基本方針」をご確認の上、ご同意いただける方は「同意する」をチェックしてください。",
        },
      },
      birthdate: {
        presence: {
          allowEmpty: false,
          message: "^西暦で入力してください",
        },
        datetime: {
          dateOnly: true,
          earliest: moment("1900-01-01"),
          message: error_messages.datetime,
        },
      },
      birthdate_year: {
        presence: true,
        numericality: true,
      },
      birthdate_month: {
        presence: true,
        numericality: true,
      },
      birthdate_day: {
        presence: true,
        numericality: true,
      },
    }
    const birthdate = formatBirthdate(form_data)
    form_data.birthdate = birthdate
    const validate_result = validate(form_data, constraints)
    return validate_result
  },
  async handleSignUp(form_data) {
    const { username, password, attributes } = form_data
    attributes.phone_number = formatPhonenumber(attributes.phone_number)
    return Auth.signUp({
      username,
      password,
      attributes,
    })
  },
}
</script>

<template>
  <authenticator
    :login-mechanisms="['email']"
    :services="services"
    :sign-up-attributes="[]"
  >
    <template v-slot:header>
      <auth-custom-header />
    </template>
    <template v-slot:sign-up-fields>
      <authenticator-sign-up-form-fields />
      <label class="amplify-label">姓</label>
      <input
        type="text"
        name="family_name"
        class="amplify-input"
        placeholder="姓"
      />
      <p
        v-if="validationErrors.family_name"
        class="amplify-text amplify-field__error-message"
      >
        {{ validationErrors.family_name[0] }}
      </p>

      <label class="amplify-label">名</label>
      <input
        type="text"
        name="given_name"
        class="amplify-input"
        placeholder="名"
      />
      <p
        v-if="validationErrors.given_name"
        class="amplify-text amplify-field__error-message"
      >
        {{ validationErrors.given_name[0] }}
      </p>

      <label class="amplify-label">生年月日</label>
      <div class="birthdate">
        <div class="year">
          <input
            type="number"
            name="birthdate_year"
            class="amplify-input"
            oninput="if(value.length > 4) value=value.slice(0,4)"
            placeholder="2001"
            pattern="^[0-9]+$"
          />
        </div>
        <div class="pr-2 pl-2">年</div>
        <div class="month">
          <input
            type="number"
            name="birthdate_month"
            class="amplify-input"
            oninput="if(value.length > 2) value=value.slice(0,2)"
            placeholder="1"
            pattern="^[0-9]+$"
          />
        </div>
        <div class="pr-2 pl-2">月</div>
        <div class="day">
          <input
            type="number"
            name="birthdate_day"
            class="amplify-input"
            oninput="if(value.length > 2) value=value.slice(0,2)"
            placeholder="1"
            pattern="^[0-9]+$"
          />
        </div>
        <div class="pr-2 pl-2">日</div>
      </div>
      <p
        v-if="validationErrors.birthdate"
        class="amplify-text amplify-field__error-message"
      >
        {{ validationErrors.birthdate[0] }}
      </p>

      <label class="amplify-label">性別</label>
      <div class="control">
        <label class="radio mr-3">
          <input type="radio" name="gender" :value="genders.man" />
          男性
        </label>
        <label class="radio mr-3">
          <input type="radio" name="gender" :value="genders.woman" />
          女性
        </label>
        <label class="radio">
          <input type="radio" name="gender" :value="genders.no_answer" />
          回答しない
        </label>
      </div>
      <p
        v-if="validationErrors.gender"
        class="amplify-text amplify-field__error-message"
      >
        {{ validationErrors.gender[0] }}
      </p>

      <label class="amplify-label">電話番号</label>
      <input
        type="tel"
        name="phone_number"
        class="amplify-input"
        placeholder="09012345678"
      />
      <p
        v-if="validationErrors.phone_number"
        class="amplify-text amplify-field__error-message"
      >
        {{ validationErrors.phone_number[0] }}
      </p>

      <label class="amplify-label">郵便番号</label>
      <input
        type="text"
        name="locale"
        class="amplify-input"
        placeholder="1008086"
        oninput="if(value.length > 7) value=value.slice(0,7)"
        pattern="^[0-9]+$"
      />
      <p
        v-if="validationErrors.locale"
        class="amplify-text amplify-field__error-message"
      >
        {{ validationErrors.locale[0] }}
      </p>

      <personal-information-check-box
        :errorMessage="validationErrors.acknowledgement"
      />
      <span class="help"
        >※携帯電話等で迷惑メール防止対策のためドメイン指定/制限を行なっている場合は「@verificationemail.com」からのメールを受信できるように設定してください。</span
      >
    </template>
    <template v-slot:confirm-sign-up-footer>
      <span class="help"
        >※携帯電話等で迷惑メール防止対策のためドメイン指定/制限を行なっている場合は「@verificationemail.com」からのメールを受信できるように設定してください。</span
      >
    </template>
    <template v-slot:confirm-reset-password-footer>
      <span class="help"
        >携帯電話等で迷惑メール防止対策のためドメイン指定/制限を行なっている場合は「@verificationemail.com」からのメールを受信できるように設定してください。</span
      >
    </template>
    <template v-slot="{ signOut }">
      <custom-header>
        <template v-slot:signout-button>
          <a @click="signOut">サインアウト</a>
        </template>
      </custom-header>
      <slot></slot>
    </template>
  </authenticator>
</template>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.birthdate {
  display: flex;
  align-items: flex-end;
}
.birthdate .year {
  width: 40%;
}
.birthdate .month {
  width: 20%;
}
.birthdate .day {
  width: 20%;
}
</style>

<template>
  <div class="content">
    <b>【スマリ宅配ボックスサービスのご利用にあたって】</b>
    <ul class="privacy-policy">
      <li>「スマリ宅配ボックスサービス」（以下「本サービス」といいます）は三菱商事株式会社が提供するサービスです。</li>
      <li>本サービスは、別途定める対象事業者でないECサイトへの発送等ではご利用いただけません。</li>
      <li>本サービスの利用方法</li>
      <ol>
        <li>ECサイト上で発送登録を実施。その際、発送方法として本サービスを選択してください。</li>
        <li>発送登録が完了すると、ECサイトからスマリの利用に必要なQRコードが表示されます。QRコード画面の下部にある「スマリ宅配ボックスから発送」を選択してください。</li>
        <li>サインインして「発送受付」を選択してください（初回利用時にはユーザー登録が必要となります）。</li>
        <li>発送商品はお客様の責任においてECサイトの規定に従って梱包したうえで、スマリ宅配ボックスに投函してください。</li>
        <li>スマリ宅配ボックスの扉を閉めて、ご利用のデバイス（スマートフォンなど）でスマリ宅配ボックスに貼り付けてあるQRコードを読み込んでください。</li>
        <li>QRコードを読み取り後、ご利用のデバイス（スマートフォンなど）に表示された暗証番号でスマリ宅配ボックスを施錠してください。</li>
      </ol>
      <li>ユーザー登録時にはご住所などのお客様情報を正確に登録してください。また、ご引越しなど、登録情報に変更が生じる際には、必ず登録情報の変更手続きを行ってください。</li>
      <li>ECサイト上で本サービスを利用する発送登録をした商品以外の物は、絶対にスマリ宅配ボックスに入れないでください。万一、対象商品以外の物が投入された場合、その配送・保管等について当社は一切責任を負いません。また、商品の引受け後は、いかなる理由であっても、商品をお返しすることはできません。</li>
      <li>本サービスでは、発送商品の配達日時の指定、速達等の対応はできませんので、発送期限等には十分余裕をもってご利用ください。大雪・大雨等の天候不調や地震等の災害その他の事由により配送遅延・配送不能が発生した場合について、当社は一切の責任を負いません。</li>
      <li>ユーザー登録の不備、登録情報変更の不備、暗証番号入力の不備、スマリ宅配ボックスの故障、破損、不具合、その他の不備に起因して商品の引受けが出来なかった場合について、当社は一切の責任を負いません。</li>
      <li>商品の配送に関する手続・費用負担・責任は、各ECサイトの規定に従います。当社は各ECサイトから発送受付および配送に関する業務を受託して行うものであり、お客様に対してはいかなる責任も負いません。</li>
    </ul>

    <b>【スマリ宅配ボックスサービスに係る個人情報保護基本方針】</b>
    <ol>
      <li>三菱商事株式会社（以下「当社」といいます）は、個人の人格を尊重し、個人情報の保護に関する法令及び行政手続における特定の個人を識別するための番号の利用等に関する法令を遵守して個人情報、および匿名加工情報（特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報であって、当該個人情報を復元することができないようにしたものをいいます。）を適切に取り扱います。</li>
      <li>個人情報および匿名加工情報等の保護に関する責任者を明確にし、社内規程を制定することにより管理体制を整備します。</li>
      <li>個人情報は、あらかじめ本人に通知し、または公表した利用目的の達成に必要な範囲内で、適正かつ適切に取得し取り扱います。</li>
      <li>個人情報および匿名加工情報等の紛失、破壊、改ざん、漏洩等を防止する為、適切な安全管理措置を講じます。</li>
      <li>個人情報および匿名加工情報等を第三者に提供する際には、法令に従い必要な措置を講じます。</li>
      <li>個人情報の開示、訂正等、利用停止等のご要望及びお問合せ並びに匿名加工情報等に関するご質問等に対しては、できる限り速やかに対応します。</li>
      <li>社内教育・啓発活動を実施して個人情報および匿名加工情報等の適正な取扱いについて周知徹底に努めるとともに、管理体制を継続的に見直します。</li>
    </ol>
    <b>個人情報の取扱いについて</b><br />
    <ol>
      <li>
        個人情報の利用目的<br />
        当社は、他の方法でお知らせする場合を除き、スマリ宅配ボックスサービス（以下「本サービス」といいます）のご利用を通じてご提供いただいた個人情報を次の目的の達成に必要な範囲で取扱います。<br />
        ・お客様に対する本サービスの提供及びEC事業者等の第三者による委託に基づく配送業務の遂行のため<br />
        ・サービスの研究・開発・改善等ならびにこれらに付随する調査・分析およびお客様に対するアンケート等の実施のため<br />
        ・その他上記の目的を達成するために必要な、個人認証・本人確認の実施、その内容、結果の管理等のため<br />
        なお、当社では上記利用目的を達成するため、提供いただいた個人情報を業務委託先に提供する場合がございます。その場合には、委託された個人情報等の安全管理が図られるよう、業務委託先に対する必要かつ適切な監督を行います。 また、法令等により提供を求められた場合には、当該公的機関に提供することがございます。
      </li>
      <li>
        第三者提供の制限<br />
        当社は、次に掲げる場合を除くほか、予めご本人の同意を得ないで、個人情報を第三者に提供しません。<br />
        (1) 法令に基づく場合<br />
        (2) 人の生命，身体または財産の保護のために必要がある場合であって，ご本人の同意を得ることが困難であるとき<br />
        (3) 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，ご本人の同意を得ることが困難であるとき<br />
        (4) 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
        (5) 契約書類において、第三者への提供があらかじめ明記されている場合<br />
        (6) 当社の業務遂行上必要な範囲内で、委託先に提供する場合
      </li>
      <li>
        安全管理措置について<br />
        当社では、個人情報を適切に管理するため、組織的、人的、物理的、技術的な観点から、適切かつ効果的な安全管理措置を明確に定めて実施致します。個人情報および匿名加工情報等の保護に関する責任者を明確にし、社内規程を制定することにより管理体制を整備します。社内教育・啓発活動を実施して個人情報および匿名加工情報等の適正な取扱いについて周知徹底に努めるとともに、管理体制を継続的に見直します。<br />
        また、当社が、第三者に個人情報の取扱いを委託する場合には、委託先の選定基準を定め、委託先の情報管理体制を確認し、委託後の業務遂行状況を監視する等委託先に対する必要かつ適切な監督を行います。<br />
      </li>
      <li>
        個人情報の正確性の確保等について<br />
        当社では、お預かりしている個人情報は、利用目的の達成に必要な範囲内において、正確かつ最新の状態で管理するように努めます。また、利用する必要がなくなったときは当該個人情報を消去します。<br />
      </li>
      <li>
        個人情報保護法に基づく開示等請求について<br />
        当社では、個人情報保護法に基づき、ご本人からの申出により、保有個人情報の利用目的の通知、保有個人情報もしくは第三者提供記録の開示、保有個人情報の訂正等、利用停止等または第三者への提供の停止に関する請求(以下「開示等請求」といいます)があった場合には、以下のように対応いたします。<br />
        (1) 開示等請求に関する手続き<br />
        開示等請求を希望される場合は下記の問い合わせ窓口にご請求下さい。当社より所定の請求用紙をお送り致しますので、書類に必要事項をご記入のうえ、下記宛先までご送付下さい。この際、ご請求者の本人確認およびご本人の情報を特定するため、以下の書類も合わせてご送付願います。請求書類等の送付に係る費用についてはご請求者のご負担となりますので、あらかじめご了承下さい。<br />
        1 ご請求者がご本人の場合<br />
        運転免許証、健康保険証またはパスポート等の公的機関が発行した書類の写し<br />
        2 ご請求者が代理人の場合<br />
        上記①の本人確認書類<br />
        代理人自身の運転免許証、健康保険証等の公的機関が発行した書類の写し<br />
        法定代理人の場合は、法定代理権があることを確認できる書類(戸籍謄本、後見開始審判書等)<br />
        委任による代理人は委任状<br />
        (2) 回答方法<br />
        お受けした開示等請求については、請求内容の確認・調査等を行い、手数料を要する請求については、入金を確認させていただいたうえで、ご本人に対してご回答致します。また代理人によるご請求の場合には、ご本人の権利・利益を侵害しないことを確認した上で、当該代理人に対しご回答申し上げます。保有個人情報または第三者提供記録の開示請求に関する回答方法は、書面または電磁的記録の提供のうち、ご本人が請求した方法によることを原則とします。<br />
        (3) 手数料<br />
        開示等請求のうち、保有個人情報の利用目的の通知または保有個人情報もしくは第三者提供記録の開示に係る請求については、手数料として1,000円（税込）をご負担いただきますので、当社指定の口座に手数料をお振込下さい。振り込み手数料についてはご請求者のご負担となりますのでご了承下さい。手数料が不足している場合、その旨ご連絡いたしますが、所定の期間内にお支払いのない場合は、ご請求がなかったものとして処理いたします。あらかじめご了承ください。<br />
        (4) 開示等請求の例外<br />
        開示等請求に応じることにより、ご本人または第三者の生命、身体、財産その他の権利・利益を害するおそれがある場合、当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合および他の法令に違反することとなる場合等請求に応じることができない場合がございますが、その場合にはその理由を付してご回答致します。
      </li>
      <li>
        プライバシーポリシーの変更について<br />
        当社は、プライバシーポリシーの内容を必要に応じて変更することがあります。プライバシーポリシーの変更を行う場合には、あらかじめ本サイトにおいて公表いたします。
      </li>
      <li>
        お問い合わせ窓口について<br />
        〒100-8086 東京都千代田区丸の内二丁目3番1号<br />
        三菱商事株式会社 食品流通・物流本部 物流開発部
      </li>
    </ol>
    <br />
    <div class="acknowledge-footer">
      以 上<br />
      〒100-8086<br />
      東京都千代田区丸の内二丁目3番1号<br />
      三菱商事株式会社<br />
      代表取締役社長 中西 勝也<br />
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
}
</script>

<style scoped>
.content {
  padding: 0;
}
.content ul {
  margin-top: 0.3em;
}
.content ol {
  margin-top: 0.5em;
}
ul ol {
  margin-top: 2px;
}
.acknowledge-footer {
  text-align: right;
}
</style>

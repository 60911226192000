import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import AmplifyVue from "@aws-amplify/ui-vue"
import awsconfig from "./aws-exports"
import Amplify from "aws-amplify"
import { jaDict } from "./lang/ja"
import VueLoading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import "bulma/css/bulma.min.css"
import "./css/app.css"
import { VuelidatePlugin } from "@vuelidate/core"

Amplify.configure(awsconfig)
Amplify.I18n.setLanguage("ja")
Amplify.I18n.putVocabularies({
  ja: jaDict,
})
createApp(App)
  .use(store)
  .use(router)
  .use(AmplifyVue)
  .use(VueLoading)
  .use(VuelidatePlugin)
  .mount("#app")
